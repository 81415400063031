@import "../../../styles/Styles.module.scss";

.section{
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $branco;

  @media screen and (max-width: 900px) {
    min-height: 50vh;
    padding-top: 50px;
    padding-bottom: 50px;
  }

}

.content{
  width: 70%;
  margin-right: 15%;
  margin-left: 15%;
}

.logo{
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}

.logo__img{
  width: 100%;
  max-width: 350px;
}

.titleA{
  font-size: 1.8em;
  font-weight: 600;
  color: $roxo;
  margin-bottom: 30px;
}

.titleB{
  font-size: 1.2em;
  font-weight: 500;
  color: $cinza;
  margin-bottom: 40px;
}

.titleC{
  font-size: 1em;
  margin-bottom: 30px;
}

.divButton{
  margin-left: -20px;
}
@import "../../styles/styles.module.scss";

.section{
  width: calc(100% - 64px);
  padding: 10px 32px 32px 32px;

  @media screen and (max-width: 900px) {
    width: calc(100% - 32px);
    padding: 16px;
  }
}

.widget__line{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 16px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;
  }
}

.widget__principal{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 330px;

  @media screen and (max-width: 500px) {
    width: 100%;
    min-height: 60px;
  }
}

.widget__value{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 120px;

  @media screen and (max-width: 500px) {
    width: 100%;
    min-height: 60px;
  }
}

.chart{
  width: 100%;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    width: 100%;
    min-height: 120px;
  }
}

.vertical{
    width: calc(65% - 16px);
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
  
    @media screen and (max-width: 500px) {
      width: calc(100%);
    }
  }

// .chartLine{
//   width: 100%;
//   min-height: 160px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   @media screen and (max-width: 500px) {
//     width: 100%;
//     min-height: 160px;
//   }
// }

.column{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 16px;
}


.widget__container40{
  width: calc(35% - 16px);
  padding: 16px;
  margin-right: 16px;
  background-color: $branco;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  min-height: 360px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);

  @media screen and (max-width: 500px) {
    width: calc(100% - 32px);
    margin-right: 0px;
    margin-bottom: 16px;
    min-height: 60px;
  }
}

.widget__container60{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;


  @media screen and (max-width: 500px) {
    width: calc(100%);
  }
}

.widget__container60:nth-child(1){
  margin-bottom: 16px;

  @media screen and (max-width: 900px) {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 0;
  }
}


.widget__container20{
  width: calc(33% - 16px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: $branco;
  border-radius: 20px;
  min-height: 160px;
  margin-right: 16px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);

  @media screen and (max-width: 500px) {
    width: calc(33.33% - 24px);
    padding: 8px;
    margin-right: 8px;
    min-height: 50px;
    margin-bottom: 16px;
  }
}

.widget__container20Roxo{
  width: calc(33% - 16px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: $roxo;
  border-radius: 20px;
  min-height: 160px;
  margin-right: 16px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);

  @media screen and (max-width: 500px) {
    width: calc(33.33% - 24px);
    padding: 8px;
    margin-right: 8px;
    min-height: 50px;
    margin-bottom: 16px;
  }
}

.widget__container20:nth-child(3){
  margin-right: 0px;
}

.widget__container20Roxo:nth-child(3){
  margin-right: 0px;
}

// .widget__container33B{
//   width: calc(40% - 16px);
//   padding: 16px;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   align-items: center;
//   background-color: $roxo;
//   border-radius: 20px;
//   min-height: 240px;
//   box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);

//   @media screen and (max-width: 500px) {
//     width: calc(100% - 32px);
//     margin-right: 0px;
//     min-height: 80px;
//   }
// }

// .legend{
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   font-size: 1.8em;
//   font-weight: 600;

//   @media screen and (max-width: 500px) {
//     font-size: 1.4em;
//   }
// }

// .legend__objective{
//   color: $verdemeio;
//   text-align: left;
// }

// .legend__done{
//   color: $roxo;
//   text-align: right;
//   margin-right: 16px;
// }

// .item{
//   width: 30px;
//   height: 30px;
//   color: $verde;
//   margin-right: 20px;
// }

.widget__title{
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  color: $roxo;
  margin-bottom: 16px;

  @media screen and (max-width: 900px) {
    font-size: 0.9em;
  }

  @media screen and (max-width: 500px) {
    font-size: 0.6em;
  }
}

.widget__titleRoxo{
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  color: $branco;
  margin-bottom: 16px;

  @media screen and (max-width: 900px) {
    font-size: 0.9em;
  }

  @media screen and (max-width: 500px) {
    font-size: 0.6em;
  }
}

// .widget__titleB{
//   width: 100%;
//   font-size: 1.2em;
//   font-weight: 600;
//   color: $branco;
//   margin-bottom: 16px;
// }

.title__icon{
  width: 25px;
  height: 25px;
  margin-right: 15px;
  margin-bottom: -6px;

  @media screen and (max-width: 900px) {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-bottom: -3px;
  }

  @media screen and (max-width: 500px) {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    margin-bottom: -3px;
  }
}

.title{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  font-weight: 600;
  color: $verdemeio;

  @media screen and (max-width: 900px) {
    font-size: 1.9em;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.5em;
  }
}

.subtitle{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  font-size: 1.5em;
  font-weight: 600;
  color: $cinza;

  @media screen and (max-width: 900px) {
    font-size: 1.2em;
  }

  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
}

.subtitleRoxo{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  font-size: 1.5em;
  font-weight: 600;
  color: $branco;

  @media screen and (max-width: 900px) {
    font-size: 1.2em;
  }

  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
}

.noData{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 2em;
  color: rgba($color: $cinza, $alpha: 0.3);
}
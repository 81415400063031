@import "./styles.module.scss";

// Container of Component
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

// title of component
.title {
  font-size: 1.6em;
  font-weight: 600;
  margin-bottom: 20px;
  color: $roxo;
  display: flex;
  align-items: center;
}

// subtitle of component
.subtitle {
  width: 90%;
  margin: auto;
  font-size: 1em;
  margin-bottom: 32px;
  color: $cinza;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;


}



.div__input {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0 30px 30px 0;
  margin-bottom: 16px;

  @media screen and (max-width: 500px) {
    width: 90%;
  }
}

.item {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  color: $verde;
}




.printKr {
  font-size: 1.7em;
  font-weight: 600;
  margin-bottom: 30px;
  color: $roxo;
  display: flex;
  align-items: center;

}

.label {
  width: 100%;
  text-transform: none;
  color: $cinza;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.9em;
}

.input {
  width: 90%;
  padding: 7px 5%;
  border-radius: 10px;
  font-weight: 600;
  font-size: 1em;
  border: 0;
  color: $cinza;
  margin-bottom: 5px;
  margin-top: 5px;
}

.inputSelect {
  width: 100%;
  padding: 7px 5%;
  border-radius: 10px;
  font-weight: 600;
  font-size: 1em;
  border: 0;
  color: $cinza;
  margin-bottom: 5px;
  margin-top: 5px;
}

.input--ok {
  outline: 2px solid rgba($color: $cinza, $alpha: 0.5)
}

.input--notOk {
  outline: 2px solid rgba($color: $vermelho, $alpha: 1)
}

.input:focus {
  outline: 2px solid rgba($color: $roxo, $alpha: 1)
}

.horizontal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.flexColumn {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col50 {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.col50:nth-child(1) {
  margin-right: 4%;

  @media screen and (max-width: 768px) {
    margin-right: 2%;
  }
}


.message {
  width: 100%;
  font-size: 0.9em;
  color: $vermelho;
  text-transform: uppercase;
}

.hidden {
  visibility: hidden;
}


.addControls {
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
    flex-direction: row;
    // flex-wrap: wrap;
    margin-top: 8px;
  }
}

.rowFlex {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col33A {
  width: 31.33%;
  margin-right: 3%;
}

.col33 {
  width: 31.34%;
}



.areas {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.singleArea {
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 0.8em;
  text-transform: uppercase;
  box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.1);
  display: flex;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 900px) {
    font-size: 0.7em;
  }
}

.singleArea--selected {
  background-color: $roxo;
  border: 2px $roxo solid;
  color: #fff;
}

.singleArea--notSelected {
  background-color: $cinzaclaro;
  border: 2px $cinzaclaro solid;
}

.singleArea__icon {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}

.singleArea__icon--active {
  fill: #fff;
  filter: invert(100%);
}


.button {
  font-size: 0.9em;
  font-weight: 600;
  display: flex;
  margin-top: 15px;
  align-items: center;
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  right: 40px;
  color: $roxo;
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px $roxo solid;
  transition: 200ms;
  background-color: transparent;

  .item__button {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 48px;
  }
}


.button:hover {
  color: $branco;
  font-size: 0.9em;
  background-color: $roxo;
  border: 2px $roxo solid;
  transition: 200ms;
  transform: scale(1.05);

  .item__button {
    margin-right: 10px;

  }
}

.admin {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.adminSelect {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.active--true {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  color: $verde;
}

.active--false {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  color: $vermelho;
}

.update {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;

}

.controls {
  display: flex;
  flex-direction: row;
}

.horizontal {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.horizontal__input {
  width: calc(100% - 16px);
  margin-right: 16px;
}

.deadline {
  width: 80%;
  margin-bottom: 24px;
  text-align: left;
  font-size: 1em;
  font-weight: 400;
  color: $cinzaescuro;

  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
@import "../../../styles/Styles.module.scss";

.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.objective{
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.okr{
  width: calc(100% - 48px);
  font-size: 1.3em;
  color: $branco;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  background: $roxo;
  background: linear-gradient(45deg, rgba(117,81,217,1) 40%, rgba(98,176,211,1) 70%, $verde 100%);
  border-radius: 16px;
  min-height: 32px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);
  padding: 16px;
  padding-left: 32px;

  @media screen and (max-width: 900px) {
    width: calc(100% - 32px);
    padding: 16px;
  }
}


.item{
  width: 25px;
  height: 25px;
  color: $verde;
  margin-right: 16px;
}
@import "styles/Styles.module.scss";

.section{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
}

.container{
  width: calc(100% - 32px);
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hr{
  width: 100%;
  border-top: 1px rgba($color: $cinza, $alpha: 0.3) dashed;
  margin-top: 16px;
  margin-bottom: 16px;

  @media screen and (max-width: 900px) {
    margin-top: 8px;
    margin-bottom: 8px;    
  }
}

.newokr{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.noData{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 2em;
  color: rgba($color: $cinza, $alpha: 0.3);
}

.title{
  width: 100%;
  text-align: center;
  margin-top: 16px;
  font-size: 1.5em;
  font-weight: 600;
  color: $roxo;
  margin-bottom: 8px;
}

.icon{
  margin-bottom: -5px;
  margin-right: 8px;
  color: $verdemeio;
}

.subtitle {
  width: 90%;
  margin: auto;
  font-size: 1em;
  color: $cinza;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;


}
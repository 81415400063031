@import "styles/styles.module.scss";

.section{
  width: 100%;
}

.actionsContainer{
  width: 92%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 16px;
}

.container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.button{
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 0;
  transition: 200ms;
  font-size: 0.8em;
  // text-transform: uppercase;
  background-color: $roxo;
  color: $branco;
  margin-left: 16px;
}

.button:hover{
  transform: scale(1.1);
  transition: 200ms;
}

.icon{
  width: 20px;
  height: 20px;
  margin-right: 8px;
}


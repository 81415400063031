@import "styles/Styles.module.scss";


.contentConfig{
  width: calc(100% - 64px);
  margin: 32px;
  margin-top: 24px;
  padding-bottom: 24px;
}

.form{
  width: 100%;
  margin: auto;
}

.field{
  margin-top: 20px;
  margin-bottom: 20px;
}

.label{
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.button{
  font-size: 1em;
  font-weight: 600;
  display: flex;
  margin-left: 8px;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  color: $branco;
  background-color: $roxo;
  border: 2px $roxo solid;
}

.button:hover{
  color: #000;
  background-color: $verde;
  border: 2px $verde solid;
  transition: 200ms;
  cursor: pointer;
}

.icon{
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.userPhoto {
  max-width: 130px;
  max-height: 130px;
  border-radius: 130px;
}

.userName{
  font-size: 1.8em;
  font-weight: 600;
  color: $verde;
  margin-top: 10px;
}
@import "../../../styles/Styles.module.scss";

.section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.title{
  color: $roxo;
  font-size: 24px;
  font-weight: 600;
}

.iconTitle{
  margin-right: 20px;
  margin-bottom: -5px;
}


.component{
  width: 80%;
  margin: auto;
}

.form{
  width: 100%;
  margin: auto;
}

.label{
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.button{
  font-size: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  color: $branco;
  background-color: $roxo;
  border: 2px $roxo solid;
}

.button:hover{
  color: #000;
  background-color: $verde;
  border: 2px $verde solid;
  transition: 200ms;
  cursor: pointer;
}

.icon{
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.contentPhotos {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}

.userPhoto {
  max-width: 130px;
  max-height: 130px;
  border-radius: 130px;
  background-color: #fff;
}

.userName{
  font-size: 1.8em;
  font-weight: 600;
  color: $verde;
  margin-top: 10px;
}

.userRole{
  font-size: 1em;
  font-weight: 600;
  color: $branco;
}
@import "styles/styles.module.scss";

.section{
  width: 100%;
}

.kanbanBoard{
  width: calc(100%);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin-top: 16px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

}

.collumn{
  width: calc(33.33%);
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-right: 1px dashed $cinza;
  min-height: calc(20vh);
  // flex-grow: 1;
  // flex-basis: 0;

  @media screen and (max-width: 500px) {
    width: calc(100%);
    // padding: 8px;
    border-right: 0;
    min-height: 5vh;
    border-bottom: 1px dashed $cinza;
  }
  
}

.collumn:last-child {
  border-right: none;
  margin-right: 0;
}

.container__task{
  width: 100%;
  padding: 0;
}

.task{
  width: calc(100% - 32px);
  background-color: $branco;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.05);
  padding: 16px;
  margin-bottom: 16px;
}


.title{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
  font-size: 1.4em;
  color: $roxo;
  font-weight: 600;
}

.title__icon{
  width: 20px;
  height: 20px;
  color: $azullogo;
  margin-right: 16px;
}


.controls{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
@import "../../../styles/Styles.module.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.form {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin-top: 16px;
  }
}

.label {
  width: calc(48%);
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.label:nth-child(1){
  margin-right: 4%;
}



.labelB {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
}


.title {
  width: calc(100% - 10px);
  font-size: 0.9em;
  text-transform: uppercase;
  color: $branco;
  padding-left: 10px;
  margin-bottom: 5px;
}

.input {
  width: calc(100% - 30px);

  padding: 10px 20px;
  border-radius: 15px;
  border: 0;
}


.addCheckpoint {
  padding: 10px 20px;
  font-size: 0.9em;
  border-radius: 10px;
  color: $azulmarinho;
  background-color: $verde;
  font-weight: 600;
  display: flex;
  align-items: center;
  border: 2px $verde solid;
  transition: 400ms;
  cursor: pointer;
  margin-top: 16px;
}

.addCheckpoint:hover {
  transform: scale(1.1);
  transition: 400ms;
  background-color: $verde;
  color: $azulmarinho;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.error {
  width: calc(90% - 16px);
  background-color: $vermelho;
  color: $branco;
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 16px;
  padding: 8px;

  @media screen and (max-width: 900px) {
    font-size: 0.8em;
    margin-bottom: 8px;
  }
}


.info {
  width: 90%;
  color: $branco;
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 16px;
  padding: 8px;

  @media screen and (max-width: 900px) {
    font-size: 0.8em;
    margin-bottom: 8px;
  }
}
@import "../../styles/Styles.module.scss";

.section{
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.printKr{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.7em;
  font-weight: 600;
  margin-bottom: 30px;
  color: $roxo;
}

.item__success{
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  color: $verde;
}

.item__notSuccess{
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  color: $vermelho;
}


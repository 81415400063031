@import "styles/styles.module.scss";

.section {
  width: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-left: 16px;


  @media screen and (max-width: 768px) {
    width: calc(100% - 16px);
    padding-left: 8px;
  }

  @media screen and (max-width: 500px) {
    width: calc(100% - 16px);
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  // border-bottom: 1px dashed rgba($color: #000000, $alpha: 0.2);
  margin-top: 16px;

  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    width: calc(100% - 16px);
  }
}

.container__left,
.container__right,
.container__center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;

  @media screen and (max-width: 768px) {
    padding: 32px 8px;
  }

  @media screen and (max-width: 500px) {
    padding: 32px 8px;
  }
}

.containerKR__center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
}

.container__right {
  width: calc(25% - 32px);
  justify-content: flex-start;
  

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.container__left {
  width: calc(35% - 48px);
  margin-right: 16px;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    width: calc(45% - 24px);
    margin-right: 8px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 500px) {
    width: calc(100% - 16px);
    margin-bottom: 8px;
  }

}

.container__center {
  width: calc(40% - 48px);
  margin-right: 16px;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    width: calc(55% - 24px);
    margin-right: 8px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 500px) {
    width: calc(100% - 16px);
    margin-bottom: 8px;
  }
}

.cursor {
  cursor: pointer;
}

.background__roxo {
  background-color: $roxo;
}

.background__degrade {
  background: $cinzaescuro;
}

.background__branco {
  background-color: $branco;
}

.background__cinza {
  background-color: $cinza2;
}



.title__left,
.title__center{
  width: 90%;
  font-size: 1.5em;
  font-weight: 600;
  // margin-bottom: 16px;
  text-align: center;
  // padding-bottom: 32px;
}

.component__title {
  width: 100%;
  font-size: 0.9em;
  font-weight: 600;
  display: flex;
  align-items: center;
}



.component__container{
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0px;

}

.title__subicon {
  margin-right: 8px;
  margin-bottom: 2px;
}

.hr{
  width: 100%;
  border-bottom: 1px dashed rgba($color: #000000, $alpha: 0.2);
  // margin-top: 32px;
  margin-bottom: 8px;
}

.space{
  width: 90%;
  margin-top: 32px;
}


.color__azul {
  color: $azulmarinho;
}

.color__branco {
  color: $branco;
}

.color__roxo {
  color: $roxo;
}

.color__verde {
  color: $verde;
}

.color__cinza{
  color: #333333;
}

.color__cinzaclaro{
  color: #b3b3b3;
}

.comparative {
  width: 100%;
  color: $branco;
  text-align: center;

}

.comparative__row{
  width: 96%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.comparative__row__col{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
    // margin-bottom: 16px;
}

.comparative__row__col__branco{
  border-right: 1px dashed $branco;
}

.comparative__row__col__cinza{
  border-right: 1px dashed $cinza;
}

.comparative__row__col__roxo{
  border-right: 1px dashed $roxo;
}


.comparative__row__col:last-child{
  border-right: none;
}


.comparative__row__col__title{
  font-size: 0.7em;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 16px;
}


.comparative__row__col__value{
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
}


.title__icon {
  width: 24px;
  height: 24px;
  margin-bottom: 16px;
}

.comparative__icon {
  margin-right: 8px;
  margin-bottom: -4px;
  color: $verde;
}

.comparative__iconRed {
  margin-right: 8px;
  margin-bottom: -4px;
  color: $vermelho;
}

.comparative__iconAzul {
  color: $azullogo;
  margin-bottom: -4px;
}

.teams {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.chart {
  width: 90%;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}


.users {
  margin-top: 10px;
  align-items: center;

  @media screen and (max-width: 900px) {
    width: 40%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.semDados {
  font-size: 1.2em;
  color: #b3b3b3;
  text-transform: uppercase;
  text-align: center;
}

.checkpoints {
  width: 96%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

// .checkpoints__title {
//   font-size: 0.8em;
//   color: #d1d1d1;
//   text-transform: uppercase;
//   margin-right: 8px;
// }

// .update33 {
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   min-height: 40px;
//   margin-right: 16px;
//   margin-bottom: 12px;
// }

.updateObs {
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.submit {
  width: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
}

.notes {
  width: 80%;
  margin: 0 auto;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.icon__link {
  width: 16px;
  height: 16px;
  margin-left: 16px;
  color: #333333;
  cursor: pointer;
}

.review {
  opacity: 0.2;
  transition: opacity 0.5s ease;
}

.prazo{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.prazo__row{
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.prazo__row__col{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


.prazo__row__col__value{
  font-size: 1em;
  font-weight: 400;
  color: $branco;
  text-align: center;
}

.icon__calendar{
  margin-right: 8px;
  margin-bottom: -4px;
  color: $azullogo;
}

@import "../../../styles/Styles.module.scss";

.section{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
}

.container{
  width: calc(100% - 50px);
  padding: 20px;
  padding-left: 30px;
  background-color: $branco;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 500px) {
    width: calc(100% - 32px);
    padding: 16px;
    flex-direction: column;
  }
}

.container__data{
  width: 60%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 500px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.container__chart{
  width: 40%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 500px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.title{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 8px;

}

.container__title{
  width: 70%;
  font-size: 1.2em;
  font-weight: 600;
  color: $roxo;
  display: flex;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    width: 60%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
  }
}

.title__icon{
  width: 25px;
  height: 25px;
  color: $roxo;
  margin-right: 8px;
  margin-bottom: -6px;
}

.teams{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.chart{
  width: 90%;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.section__icon{
  width: 20px;
  height: 20px;
  color: $cinza;
  margin-left: 30px;
  margin-right: 10px;

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.users{
  width: 30%;
  align-items: center;
  
  @media screen and (max-width: 900px) {
    width: 40%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
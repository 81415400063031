@import "../../../styles/Styles.module.scss";

.section{
  width: calc(100% - 64px);
  padding: 10px 32px 32px 32px;

  @media screen and (max-width: 900px) {
    width: calc(100% - 32px);
    padding: 16px;
  }
}

.kr {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 120px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.kr__header{
  width: calc(59% - 16px);
  padding: 16px;
  margin-right: 16px;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin-right: 0px;
  }
}

.kr__title{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  font-size: 1.8em;
  font-weight: 600;

  @media screen and (max-width: 900px) {
    font-size: 1.3em;
  }
}

.kr__actions{
  width: calc(40% - 16px);
  padding: 16px;
  font-size: 0.7em;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 500px) {
    width: 100%;
    justify-content: center;
    padding-left: 0;
  }
}

.kr__teams{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.widget__line{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 16px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.widget__value{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.chart{
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    width: 100%;
    min-height: 120px;
  }
}

.chartLine{
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    width: 100%;
    min-height: 300px;
  }
}




.widget__container66{
  width: calc(59% - 16px);
  padding: 16px;
  margin-right: 16px;
  background-color: $branco;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  min-height: 240px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);

  @media screen and (max-width: 500px) {
    width: calc(100% - 32px);
    margin-right: 0px;
    margin-bottom: 16px;
  }
}

.widget__container33{
  width: calc(40% - 16px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: $branco;
  border-radius: 20px;
  min-height: 240px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);

  @media screen and (max-width: 500px) {
    width: calc(100% - 32px);
    margin-right: 0px;
    min-height: 80px;
  }
}

.widget__container33B{
  width: calc(40% - 16px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: $roxo;
  border-radius: 20px;
  min-height: 240px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);

  @media screen and (max-width: 500px) {
    width: calc(100% - 32px);
    margin-right: 0px;
    min-height: 80px;
  }
}

.legend{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.8em;
  font-weight: 600;

  @media screen and (max-width: 500px) {
    font-size: 1.4em;
  }
}

.legend__objective{
  color: $verdemeio;
  text-align: left;
}

.legend__done{
  color: $roxo;
  text-align: right;
  margin-right: 16px;
}

.item{
  width: 30px;
  height: 30px;
  color: $verde;
  margin-right: 20px;
}

.widget__title{
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  color: $roxo;
  margin-bottom: 16px;
}

.widget__titleB{
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  color: $branco;
  margin-bottom: 16px;
}

.title__icon{
  width: 25px;
  height: 25px;
  margin-right: 15px;
  margin-bottom: -6px;
}

.teams{
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}


.checkpoints{
  width: 100%;
  background-color: $branco;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
  padding: 16px;

  @media screen and (max-width: 500px) {
    width: calc(100% - 16px);
    margin-right: 0px;
    padding: 8px;
  }
}

.checkpoints__titleButton{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $roxo;
  // margin-bottom: 32px;
}

.checkpoints__title{
  font-size: 1.2em;
  font-weight: 600;
  color: $roxo;
  margin-right: 32px;
}


.update__table{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 8px;
  }
}

.update__row{
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 8px;
  border-bottom: 1px dashed rgba($color: $cinza, $alpha: 0.3);

  @media screen and (max-width: 500px) {
    width: calc(100% - 16px);
    margin-bottom: 8px;
  }
}

.update__rowHeader{
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 600;
  color: $roxo;
  padding: 8px;
  border-bottom: 1px dashed rgba($color: $cinza, $alpha: 0.3);

  @media screen and (max-width: 500px) {
    width: calc(100% - 16px);
    margin-bottom: 8px;
  }
}

.update__line1{
  width: 64%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.update__date{
  width: 30%;
  text-align: left;
}

.update__value{
  width: 25%;
  text-align: center;
}

.update__responsible{
  width: 30%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.update__remove{
  width:15%;
  text-align: center;
}

.update__obs{
  width:36%;
  text-align: left;
  font-style: italic;
  color: $cinza;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin-top: 8px;
  }
}
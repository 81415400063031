@import "./styles.module.scss";

.icon{
  width: 70px;
  height: 70px;
  color: $verde;
}

.copy{
  width: 30px;
  height: 30px;
  color: $roxo;
  margin: 20px;
}

.pointer{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 40px;
}
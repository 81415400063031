@import "styles/styles.module.scss";

.container{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title{
  margin-top: 10px;
  font-size: 1.1em;
  margin-bottom: 10px;
}

.title__done{
  margin-top: 10px;
  font-size: 1.1em;
  margin-bottom: 10px;
  text-decoration: line-through;
}

.description{
  font-size: 0.9em;
  color:#8b8b8b;
}

.description__done{
  font-size: 0.9em;
  color:#8b8b8b;
  text-decoration: line-through;
}

.rodape{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  // border-top: 1px dashed #cccccc;
  // padding-top: 20px;
}

.users{
  width: 50%;
}


.deadline{
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8b8b8b;
}

.deadline__done{
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $verde;
}

.deadline__delay{
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $vermelho;
}

.comments{
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8b8b8b;
  max-width: 50px;
}

.comments__icon{
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: -4px;
}
@import "styles/Styles.module.scss";

.section{
  width: calc(100% - 64px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px;
  border: 0;
  background-color: $branco;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
  padding: 16px 32px 16px 32px;
  margin-bottom: 20px;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    width:calc(100% - 32px);
    flex-direction: column;
    padding: 8px 16px 8px 16px;
  }
}

.section__icon{
  width: 24px;
  height: 24px;
  color: $cinza;
}

.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.line{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  flex-wrap: wrap;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
}

.name{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5em;
  color: $roxo;
  font-weight: 600;
  margin-right: 32px;

  @media screen and (max-width: 500px) {
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}

.photo{
  width: 64px;
  height: 64px;
  margin-right: 32px;
  border-radius: 100%;

  @media screen and (max-width: 500px) {
    margin-bottom: 16px;
    margin-top: 16px;
  }
}

.status{
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 500px) {
    text-align: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
}

.icon{
  width: 15px;
  height: 15px;
  margin-right: 10px;
  color: $cinza;
}

.active--true{
  width: 20px;
  height: 20px;
  margin-right: 16px;
  color: $verde;
}

.active--false{
  width: 20px;
  height: 20px;
  margin-right: 16px;
  color: $vermelho;
}
@import "../../../styles/styles.module.scss";

.container{
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $cinzaclaro;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.title{
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1em;
  font-weight: 500;
}

.icon{
  width: 16px;
  height: 16px;
  margin-right: 16px;
  color: $roxo;
}

.krs{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.actions{
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.edit{
  width: 16px;
  height: 16px;
  color: $cinza;
  cursor: pointer;

}

.remove{
  width: 13px;
  height: 13px;
  margin-right: 16px;
  color: $cinza;
  cursor: pointer;
}
@import "styles/styles.module.scss";

.container{
  min-width: 400px;

  @media screen and (max-width: 500px) {
    width: 100%;
    min-width: 300px;
  }
}

.close{
  color:$cinza;
  position: absolute;
  right: 16px;
  top:16px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.title{
  width: 100%;
  text-align: left;
  font-size: 1.3em;
  font-weight: 600;
  color: $roxo;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.clear{
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
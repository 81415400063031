@import "../../../styles/Styles.module.scss";

.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.objective{
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
}


.okrX{
  width: calc(34% - 48px);
  font-size: 1.3em;
  color: $branco;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: $cinzaclaro;
  border-radius: 20px;
  min-height: 120px;
  margin-right: 16px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
  padding: 16px;

  @media screen and (max-width: 900px) {
    width: calc(100% - 16px);
    margin-right: 0;
    margin-bottom: 16px;
    padding: 8px;
  }
}

.krX{
  width: calc(100%);
 
  background-color: $cinzaclaro;
  border-radius: 20px;
  min-height: 80px;
  margin-right: 16px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
  padding: 16px;

  @media screen and (max-width: 900px) {
    width: calc(100% - 16px);
    margin-right: 0;
    margin-bottom: 16px;
    padding: 8px;
  }
}

.container__header{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.title{
  font-size: 1.3em;
  color: $cinza;
  font-weight: 400;
  margin: 32px;
}
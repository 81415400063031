@import "../../../styles/Styles.module.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;

  @media screen and (max-width: 900px) {
    margin-left: 50px
  }
}

.icon {
  width: 20px;
  height: 20px;
  color: $cinza;
  margin-right: 10px;

  @media screen and (max-width: 900px) {
    color: #fff;
  }
}

.text {
  font-size: 14px;
  font-weight: 500;
  color: $cinza;

  @media screen and (max-width: 900px) {
    color: #fff;
  }
}
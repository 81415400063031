@import "../../styles/Styles.module.scss";


.mobile__header {
  width: 100%;
  background-color: $branco;
  min-height: 15vh;

  @media screen and (max-width: 900px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: 60px;
    position: sticky;
    top: 0;
    box-shadow: 3px 3px 5px rgba($color: #000000, $alpha: 0.1);
  }
}

.logo {
  width: 100%;

  @media screen and (max-width: 900px) {
    width: 70%;
  }
}

.logo__img {
  max-width: 70%;
  margin-left: 20px;
  margin-top: 30px;

  @media screen and (max-width: 900px) {
    max-height: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.toggle {
  display: none;

  @media screen and (max-width: 900px) {
    width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
  }
}

label {
  display: flex;
  flex-direction: column;
  // position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  cursor: pointer;
  margin-right: 20px;
}

label span {
  background: $roxo;
  border-radius: 10px;
  height: 4px;
  margin: 4px 0;
  transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  width: 100%;
}


input[type="checkbox"] {
  display: none;
}


input[type="checkbox"]:checked~span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(7px, 8px)
}


input[type="checkbox"]:checked~span:nth-of-type(2) {

  transform-origin: top;
  transform: rotatez(-45deg)
}


input[type="checkbox"]:checked~span:nth-of-type(3) {

  transform-origin: bottom;
  width: 50%;
  transform: translate(12px, -8px) rotatez(45deg);
}



////////////////////////////////



.menu {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.menu--hidden {

  min-height: calc(85vh);

  @media screen and (max-width: 900px) {
    display: none;
    visibility: hidden;
    height: 1px !important;
  }
}

.menu--active {
  min-height: calc(100vh - 60px);

  @media screen and (max-width: 900px) {
  background-color: $roxo;
  }
}



.menu__icon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

nav {
  margin: 0;
  width: 100%;
  padding: 0;
  font-size: 1em;
  height: 70vh;
  display: flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    justify-content: center;
  }

}


nav a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding-inline-start: 0;
  min-width: 100%;

  @media screen and (max-width: 900px) {
    min-width: 10%;
  }
}

// .li {
//   text-decoration: none;
//   display: flex;
//   align-items: center;
//   color: $roxo;
//   padding-inline-start: 30px;
//   padding-bottom: 15px;
//   padding-top: 15px;
// }

li {
  width: 100%;
  list-style: none;
  
}

li:hover {

  color: $roxo;
  background-color: rgba($color: $cinza, $alpha: 0.1);
  border-right: 3px $roxo solid;

  @media screen and (max-width: 900px) {
    color: $azulmarinho;
    background-color: transparent;
    border-right: 0;
  }
}

li:hover .title {
  transition: 200ms;
  color: $roxo;
  font-weight: 600;

  @media screen and (max-width: 900px) {
    color: $azulmarinho;
  }
}

li a {
  display: flex;
  align-items: center;
  color: $cinza;
  transition: 200ms;
  padding-inline-start: 30px;
  padding-bottom: 15px;
  padding-top: 15px;

  @media screen and (max-width: 900px) {
    color: $cinzaclaro;
  }

}

li a:hover {
  color: $roxo;
  transform: scale(1.1);
  transition: 200ms;

  @media screen and (max-width: 900px) {
    color: $azulmarinho;
  }
}

.active {
  color: $roxo;
  font-weight: 600;
  font-size: 1.1em;

  @media screen and (max-width: 900px) {
    color: $azulmarinho;
  }
}

.title {
  transition: 200ms;
}

.user {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.userContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.user__img {
  width: 40px;
  height: 40px;
  margin-left: 30px;
  border: 2px $roxo solid;
  border-radius: 50%;

  @media screen and (max-width: 900px) {
    margin-left:0;
    margin-right: 20px;
    border: 2px $cinzaclaro solid;
  }
}

.user__infoA {
  width: 40%;

  @media screen and (max-width: 900px) {
    width: 50%;
    text-align: right;
  }
}

.user__infoB {
  width: 60%;

  @media screen and (max-width: 900px) {
    width: 50%;
  }
}

.user__nome {
  font-weight: 600;
  color: $roxo;

  @media screen and (max-width: 900px) {
    color: $branco;
    font-size: 1.1em;
  }
}

.user__cargo {
  color: $cinza;

  @media screen and (max-width: 900px) {
    color: $cinzaclaro;
    font-size: 0.9em;
  }
}


.plus{
  width: 20px;
  height: 20px;
  color: rgba($color: $cinza, $alpha: 0.5);
  margin-right: 20px;



}

.addKr:hover{
  transform: scale(1.2);
  transition: 400ms;
  color: $roxo;
  font-weight: 600;

  .plus{
    color: $roxo;
  }
}

.addKr{
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: rgba($color: $roxo, $alpha: 1) ;
  transition: 400ms;
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  color: $cinza;
  border: 0;
}

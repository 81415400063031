@import "../../../styles/styles.module.scss";

.singleArea {
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 3em;
  text-transform: uppercase;
  box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.1);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.singleArea--selected {
  background-color: $roxo;
  border: 2px $roxo solid;
  color: #fff;
}

.singleArea--notSelected {
  background-color: $cinzaclaro;
  border: 2px $cinzaclaro solid;
}

.singleArea__icon {
  width: 24px;
  height: 24px;
  margin: 4px;
}

.singleArea__icon--active {
  fill: #fff;
  filter: invert(100%);
}
@import "styles/Styles.module.scss";

.section{
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.info{
  width: 50%;

  @media screen and (max-width: 900px) {
    width: 100%;
    min-height: 50vh;
  }
}

.firebase{
  width: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    width: 100%;
    min-height: 50vh;
  }
}

.loginArea{
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
}

.loginTitle{
  width: 100%;
  text-align: center;
  font-size: 1.3em;
  color: $roxo;
  font-weight: 600;
  margin-bottom: 30px;
}

.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field{
  width: calc(100% - 40px);
  border-radius: 15px;
  padding: 10px 20px;
  margin-bottom: 15px;
  border: 1px rgba($color: $cinza, $alpha: 0.3) solid;
}

.label{
  width: calc(100% - 20px);
  text-align: left;
  padding-left: 20px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.errorMessage{
  width: 100%;
  text-transform: uppercase;
  color: $vermelho;
  font-weight: 600;
  margin-bottom: 15px;
}

.divButton{
  width: 85%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: -25px;
}

.forgotPassword{
  width: 100%;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  color: $roxo;
}
@import "../../../styles/Styles.module.scss";

.timeline{
  width: 100%;
}
.info{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoA{
  width: 60%;
  text-align: center;
}

.infoB{
  width: 40%;
  font-size: 1.1em;
  color: $cinza;
}

.title{
  font-size: 3em;
  font-weight: 600;
  color: $roxo;
}

.subtitle{
  width: 100%;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 0.8em;
}

.separator {
  width: 100%;
  border: 1px $cinza dashed;
}
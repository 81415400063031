@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@400;600;800;900&display=swap');

$roxo : #7551d9;
$roxo2 : #5e3abf;
$azulmarinho : #181f4d;
$azul : rgb(98, 176, 211);
$azullogo : rgb(65, 236, 249);
$laranja : #e3a241;
$cinza : #6f7577;
$cinza2 : #e9e9e9;
$cinzaescuro : #555555;
$vermelho : #E03859;
$verde : rgb(36, 255, 143);
$branco : #ffffff;
$cinzaclaro : #f1f1f1;
$verdemeio :#3cdfaa;
$degrade: linear-gradient(45deg, rgba(117,81,217,1) 50%, rgba(98,176,211,1) 100%);
$degrade2: linear-gradient(45deg, rgba(62,236,249,1) 0%, rgba(36,255,143,1) 100%);
$degrade3: linear-gradient(45deg, $verdemeio 0%, $azulmarinho 100%,);


html {
  font-family: 'Blinker', sans-serif;
}

body {
  background-color: $cinzaclaro;
}

.logo{
  max-width: 250px;
  width: 100%;
}
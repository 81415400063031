@import "../../../styles/styles.module.scss";

.progressBar{
  padding: 0;
  margin: 0;
  width: 100%;

}

.container{
  width: 100%;
  height: 60px;
}

.title{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  font-weight: 600;
  text-transform: uppercase;
  color: $verdemeio;
  margin-bottom: 5px;
}
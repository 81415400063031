@import "../../../styles/Styles.module.scss";

.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;

  @media screen and (max-width: 900px) {
    margin-bottom: 0px;
  }
}

.okr{
  width: calc(100% - 32px);
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $branco;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);
  padding: 32px 16px;

  @media screen and (max-width: 900px) {
    width: calc(100% - 32px);
    margin-right: 0;
    margin-bottom: 16px;
    padding: 16px;
  }

  @media screen and (max-width: 500px) {
    width: calc(100% - 32px);
    flex-direction: column;
    padding: 16px;
  }
}

.title{
  width: 45%;
  text-align: left;
  font-size: 1.3em;
  font-weight: 600;
  color: $roxo;

  @media screen and (max-width: 900px) {
    font-size: 1.2em;
    width: 40%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
  }
}

.chart{
  width: 50%;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.go{
  width: 5%;
}


.item{
  width: 25px;
  height: 25px;
  color: $verde;
  margin-bottom: -5px;
  margin-right: 16px;

  @media screen and (max-width: 500px) {
    margin-bottom: 16px;
  }
}

.section__icon{
  width: 20px;
  height: 20px;
  color: $cinza;
  margin-left: 30px;
  margin-right: 10px;

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.teams{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}
@import "styles/Styles.module.scss";

.userPhoto {
  max-width: 130px;
  max-height: 130px;
  border-radius: 130px;
}

.userName{
  font-size: 1.8em;
  font-weight: 600;
  color: $verde;
  margin-top: 10px;
}

.userRole{
  font-size: 1em;
  font-weight: 600;
  color: $branco;
}


.menuConfig{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed rgba($color: #000000, $alpha: 0.2);
  padding-bottom: 24px;
  padding-top: 24px;
  cursor: pointer;
}

.contentConfig{
  margin-top: 24px;
  border-bottom: 1px dashed rgba($color: #000000, $alpha: 0.2);
  padding-bottom: 24px;
}

.title{
  color: $roxo;
  font-size: 24px;
  font-weight: 600;
}

.iconTitle{
  margin-right: 20px;
  color: $roxo;
  width: 18px;
  height: 18px;

}

.field{
  margin-top: 20px;
  margin-bottom: 20px;
}


.component{
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  margin: auto;
  height: calc(100vh - 30px);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 96%;
    padding-left: 2%;
    padding-right: 2%;
  }
}

.form{
  width: 100%;
  margin: auto;
}

.label{
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.button{
  font-size: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  color: $branco;
  background-color: $roxo;
  border: 2px $roxo solid;
}

.button:hover{
  color: #000;
  background-color: $verde;
  border: 2px $verde solid;
  transition: 200ms;
  cursor: pointer;
}

.icon{
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.iconOpen{
  margin-left: 16px;
  margin-right: 16px;
}
@import "styles/Styles.module.scss";

.container{
  width: calc(100% - 64px);
  margin: auto;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 0;
  background-color: $branco;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
  padding: 32px 16px 32px 16px;
  justify-content: center;
  align-items: center;
}

.business{
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  align-items: center;
  justify-content: flex-start;
}

.name{
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  cursor: pointer;
}

.plan{
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: none;
  cursor: pointer;
}

.users{
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: none;
  cursor: pointer;
}


.active{
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: none;
  cursor: pointer;
}


.icon{
  width: 16px;
  height: 16px;
  margin-right: 16px;
  color: $roxo;
}

.icon__edit{
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: $cinza;
  margin-right: 16px;
}

.active--true{
  width: 16px;
  height: 16px;
  margin-right: 16px;
  color: $verde;
}

.active--false{
  width: 16px;
  height: 16px;
  margin-right: 16px;
  color: $vermelho;
}
@import "../../../styles/Styles.module.scss";

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 16px;

  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    margin-right: 0px;
  }
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  margin-bottom: 3px;
}

.userPhoto {
  width: 35px;
  height: 35px;
  // border: 2px solid $verde;
  border-radius: 100%;
  margin-right: -10px; /* margem negativa para sobreposição */
}

.userPhotoWrapper {
  margin-right: 3px; /* margem positiva para o div pai */
}

@import "styles/styles.module.scss";

.container{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.meeting{
  width: calc(25% - 10px);

  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.1);
  display: flex;
  align-items: center;
  min-height: 40vh;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: calc(50% - 10px);
    min-height: 30vh;
  }

  @media screen and (max-width: 500px) {
    width: calc(100% - 10px);
  }

}

.meeting__closed{
  background-color: $branco;
  transition: 1s;
  cursor: pointer;
}

.meeting__add{
  background: $roxo;
  transition: 1s;
  cursor: pointer;
}

.color__roxo{
  color: $roxo;
}

.color__branco{
  color: $branco;
}

.color__azulmarinho{
  color: $azulmarinho;
}

.meeting__open{
  background: $degrade2;
  transition: 1s;
  cursor: pointer;
}

.meeting__none{
  background-color: #eaeaea;
}

.meeting__closed:hover{
  background-color: #d2d2d2;
  transition: 0.5s;
}

.meeting__open:hover{
  background-color: #17cc6f;
  transition: 1s;
}

.meeting__add:hover{
  background-color: $roxo2;
  transition: 1s;
}

.meeting__title{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 8px;
}

.meeting__icon{
  width: 32px;
  height: 32px;
  margin-bottom: 16px;
}

.space{
  height: 16px;
}

.modal__container{
  min-width: 400px;
  justify-content: center;


  @media screen and (max-width: 500px) {
    width: 100%;
    min-width: 300px;
  }
}

.close{
  color:$cinza;
  position: absolute;
  right: 16px;
  top:16px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.title{
  width: 100%;
  text-align: left;
  font-size: 1.3em;
  font-weight: 600;
  color: $roxo;
  margin-bottom: 32px;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.clear{
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
@import "../../../styles/Styles.module.scss";

.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.objective{
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.item{
  width: 25px;
  height: 25px;
  margin-bottom: 8px;
  color: $verde;
}

.itemB{
  width: 25px;
  height: 25px;
  margin-left: 20px;
  margin-bottom: 8px;
  color: $roxo;
}




.okr{
  width: calc(34% - 48px);
  font-size: 1.3em;
  color: $branco;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: $roxo;
  border-radius: 20px;
  min-height: 120px;
  margin-right: 16px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);
  padding: 16px;

  @media screen and (max-width: 900px) {
    width: calc(100% - 16px);
    margin-right: 0;
    margin-bottom: 16px;
    padding: 8px;
  }
}

// .column{
//   display: flex;
//   flex-direction: row;
//   width: 66%;

//   @media screen and (max-width: 900px) {
//     width: 100%;
//     flex-direction: row;
//   }

//   @media screen and (max-width: 500px) {
//     width: 100%;
//     flex-direction: column;
//   }
// }

.status{
  width: calc(33% - 48px);
  background-color: $branco;
  border-radius: 20px;
  min-height: 120px;
  margin-right: 16px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    width: calc(33% - 16px);
    margin-bottom: 16px;
    padding: 8px;
  }

  @media screen and (max-width: 500px) {
    width: calc(100% - 16px);
    margin-right: 0;
    margin-bottom: 16px;
    padding: 8px;
  }


}

.timeline{
  width:  calc(33% - 32px);
  background-color: $branco;
  border-radius: 20px;
  min-height: 120px;

  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    width: calc(50% - 16px);
    margin-right: 0;
    margin-bottom: 16px;
    padding: 8px;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}


// .title{
//   font-size: 0.8em;
//   text-transform: uppercase;
//   width: 100%;
//   margin-left: 20px;
//   width: calc(100% - 20px);
//   display: flex;
//   align-items: center;
// }


.separator{
  width: 100%;
  margin-bottom: 32px;
  border: 1px rgba($color: #000000, $alpha: 0.2) dashed;
  margin-top: 32px;

  @media screen and (max-width: 900px) {
    margin-bottom: 16px;
    margin-top: 16px;
  }
}

.hr{
  width: 100%;
  margin-bottom: 16px;
}

.newkrs{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.actions{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border-top: 1px rgba($color: #000000, $alpha: 0.2) dashed;
  padding-top: 8px;
}


.actions__table{
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.actions__row{
  width: 50%;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgba($color: $branco, $alpha: 0.5);
}

.actions__icon{
  width: 15px;
  height: 15px;
  margin-right: 8px;
  // color: rgba($color: $branco, $alpha: 0.5);
  transition: 200ms;
  cursor: pointer;
}

.actions__row:hover{
  transform: scale(1.2);
  color: rgba($color: $branco, $alpha: 1);
  transition: 200ms;
}

.actions__text{
  font-size: 0.6em;
  // color: rgba($color: $branco, $alpha: 0.5);
  transition: 200ms;
  cursor: pointer;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@import "../../../styles/Styles.module.scss";

.singleTeam {
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.1);
  display: flex;
  align-items: center;
  background-color: $cinzaclaro;
  border: 0;
  color: $azulmarinho;

  @media screen and (max-width: 900px) {
    font-size: 0.5em;
  }
}

.icon {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  filter: invert(40%);
}
@import "../../../styles/Styles.module.scss";

.progressBar{
  width:90%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.step{
  width: 30px;
  height: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 30px;
}

.step--inactive{
  color: rgba($color: $branco, $alpha: 0.5);
  border: 2px rgba($color: $branco, $alpha: 0.5) solid;
}

.step--complete{
  background-color: $verde;
  border: 2px $verde solid;
}

.step--active{
  background-color: $branco;
  border: 2px $branco solid;
}

.divisor{
  width: 12px;
  height: 4px;
}

.item{
  display: flex;
  flex-direction: row;
}
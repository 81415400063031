@import "styles/Styles.module.scss";

.section{
  width: calc(100% - 64px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px;
  border: 0;
  background-color: $cinzaclaro;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
  padding: 8px 32px 8px 32px;
  margin-bottom: 16px;

  @media screen and (max-width: 500px) {
    width:calc(100% - 32px);
    flex-direction: column;
    padding: 8px 16px 8px 16px;
  }
}

.section__icon{
  width: 24px;
  height: 24px;
  color: $cinza;
}

.container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.line{
  width: calc(100% - 10px);
  margin-right: 10px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
}

@import "../../../styles/styles.module.scss";

.container{
  width: 100%;
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 900px) {
    max-height: 200px;
  }
}

.container__title{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  font-weight: 600;
  color: $roxo;
  text-transform: uppercase;
  margin-bottom: 5px;
}
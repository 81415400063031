@import "styles/Styles.module.scss";

.contentConfig{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 64px);
  padding: 32px;
  margin-top: 24px;
  padding-bottom: 24px;
}
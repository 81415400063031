@import "styles/styles.module.scss";

.addTask{
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.05);
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.addTask:hover{
  background-color: $verde;
  font-weight: 600;
}

.addTask__icon{
  width: 16px;
  height: 16px;
  margin-right: 16px;
}

.title__okr{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.3em;
  color: $roxo;
  width: 100%;
  font-weight: 600;
  margin-bottom: 16px;
}

.title__kr{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.1em;
  color: $cinza;
  width: 100%;
  font-weight: 600;
  margin-bottom: 16px;
}


.icon{
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}

.contentUsers {
  display: block;
  width: 100%;
  margin-top: 5px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.userPhoto {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 15px;
}

@import "./styles.module.scss";


.section{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}


.view{
  width: calc(30% - 32px);
  padding: 16px;
  display: flex;
  justify-content: center;
  background: $roxo;
  background: linear-gradient(45deg, rgba(117,81,217,1) 50%, rgba(98,176,211,1) 100%);
  min-height: calc(100vh - 64px);
  text-align: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);

  @media screen and (max-width: 768px) {
    width: calc(100% - 32px);
    padding: 16px;
    min-height: 100px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}


.content{
  width: calc(70% - 60px);
  padding: 15px;
  margin-left: 10px;
  display: flex;
  overflow: auto;
  height: calc(100vh - 30px);
  justify-content: flex-start;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
  align-items: center;
  background-color: $branco;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: calc(100%);
    padding: 0;
    min-height: 100px;
    margin: 0;
  }
}

.printObjective{
  width: 100%;
  font-size: 1.6em;
  color: $branco;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-weight: 600;
  margin-bottom: 16px;

  @media screen and (max-width: 900px) {
    font-size: 1.2em;
  }

  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
}

.item{
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
  color: $verde;

  
  @media screen and (max-width: 900px) {
    height: 24px;
    width: 24px;
  }
}

.container{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 30px);
  overflow: auto;

  @media screen and (max-width: 900px) {
    min-height: 10vh;
    justify-content: flex-start;
    padding-top: 30px;
  }



}

.controls{
  width: calc(100% - 64px);
  margin: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@import "styles/Styles.module.scss";

.section{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.controls{
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.users{
  width: 100%;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.title{
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 1.3em;
  text-align: center;
  font-weight: 600;
  color: $roxo;
}


.invite{
  width: calc(100% - 64px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px;
  border: 0;
  background-color: $cinzaclaro;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
  padding: 8px 32px 8px 32px;
  margin-bottom: 16px;

  @media screen and (max-width: 500px) {
    width:calc(100% - 32px);
    flex-direction: column;
    padding: 8px 16px 8px 16px;
  }
}